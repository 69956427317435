.christmas-banner {
  border: 2px solid var(--action-color);
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition:
    opacity 500ms,
    transform 500ms,
    margin 500ms 100ms,
    height 500ms 300ms,
    padding 500ms 100ms;

  a {
    color: var(--action-color);
  }

  &.curtain {
    display: none;
  }

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
    margin: 0;
    padding: 0;
    height: 0 !important;
  }
}

.christmas-banner--bottom {
  display: flex;
  justify-content: center;
}

.christmas-banner--close {
  cursor: pointer;
  background: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2rem;
  font-size: 1rem;
  margin-top: 0.5rem;
  color: var(--action-color);
  text-decoration: underline;
}
