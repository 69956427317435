:root {
  --bg-color: white;
  --text-color: black;
  --light-text-color: #949494;

  --deactive: #c4c4c4;

  --deactive-bg: #e9e9e9;

  --success: #4cb5ab;

  --header-bg-color: #96dbc6;
  --header-font-color: #fff;

  --main-bg-color: #fff;

  --footer-bg-color: #fedf82;
  --footer-font-color: #696969;

  --hint-color: #fedf82;

  --link-color: #ff9933;

  --error-color: rgba(255, 0, 0, 0.5);

  //--action-color: #fe8b77;
  --action-color: #4dbc9a;
  --action-fg-color: #fff;
}
