#delete-dialog {
  dialog {
    padding-bottom: 1rem;
  }
}

.delete-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  word-break: break-word;
}

.delete-buttons {
  text-align: center;
}

.delete-buttons button {
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 10rem;
}

@media screen and (max-width: 50rem) {
  .delete-buttons button {
    width: 8rem;
    min-width: 8rem;
    padding: 8px;
  }
}
