.enhanced-select {
  margin: 0 !important;
  padding: 0 !important;
}

.enhanced-select--button {
  border: none;
  background: white;
  width: 32px;
  height: 32px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }

  &:focus,
  &:hover {
    border-radius: 50%;
    background-color: var(--action-color);
    color: var(--action-fg-color);
    svg {
      path {
        fill: white;
      }
    }
  }
}
.enhanced-select--headline {
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--deactive);
}

.enhanced-select--select {
  font-size: 1rem;
  background: white;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem !important;
  cursor: pointer;
  display: block;
  color: var(--text-color);
  margin: 0;
  line-height: 1rem;
  text-decoration: none;
  border: none;
  outline: none;

  &:focus,
  &:hover {
    background-color: var(--action-color);
    color: var(--action-fg-color);
  }
}

.enhanced-select--selected {
  background-color: var(--action-color);
  color: var(--action-fg-color);
}
