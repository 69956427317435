.list--header {
  width: 100%;
  display: grid;
  grid-template-areas:
    'list-header-title list-header-buttons'
    'list-header-author list-header-buttons'
    'list-header-actions list-header-actions';
  grid-template-columns: 1fr auto;
}

.list--description-html {
  margin-bottom: 1rem;
}

.list--actions {
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  button,
  a {
    margin-right: 0.5rem;
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.list--header--back {
  margin-top: -1em;
  margin-bottom: 1em;
  padding-top: 1em;
  a {
    color: var(--light-text-color);
  }
}

.list--header--title {
  width: 100%;
  grid-area: list-header-title;
  margin-bottom: 0;
}

.list--author {
  margin-bottom: 0.5rem;
  grid-area: list-header-author;
  color: var(--light-text-color);
}

.list--header--buttons {
  grid-area: list-header-buttons;
}

.list--items {
  list-style: none;
  margin-left: 0;
}

.list--item {
  background-color: white;
}

.list--item--inner {
  border: 1px solid var(--hint-color);
  display: grid;
  margin-bottom: 2em;
  grid-template-areas:
    'listItemImage listItemText'
    'listItemImage listItemDescription'
    'listItemBottomRow listItemBottomRow';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
}

.list--item--bottom-row {
  grid-area: listItemBottomRow;
  display: flex;
}

.list--item--completed {
  border: 1px solid var(--deactive) !important;

  .list--item--image {
    opacity: 0.5;
  }
  .list--item--text {
    opacity: 0.5;
  }
}

.list--item--completed--button {
  grid-area: listItemCompleted;
  display: flex;
  padding-left: 1rem;
  padding-bottom: 1rem;
  justify-content: flex-start;
  align-items: flex-end;
}

.list--item--image {
  grid-area: listItemImage;
  padding-right: 1rem;
  line-height: 0;

  a img,
  img {
    width: 12rem;
    height: 12rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
  }
}

.list--item--text {
  grid-area: listItemText;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  font-weight: 900;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list--item--description {
  grid-area: listItemDescription;
}

.list--item--isbn {
  font-size: 1rem;
  letter-spacing: 0.025rem;
  font-weight: 900;
  color: var(--text-color);
  padding-bottom: 0.5rem;
}

.list--item--price {
  font-size: 1rem;
  letter-spacing: 0.025rem;
  font-weight: 900;
  color: var(--text-color);
}

.list--item--text--header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.list--item--text--link {
  margin-bottom: 0.5rem;
}

.list--item--text--link,
.list--item--text--link * {
  font-weight: 900;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
  word-break: break-word;
  max-width: max-content;
}

.list--item--text--claimed-wrapper {
  margin-top: -10px;
  padding-left: 1rem;
}

.list--item--text--claimed {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--light-text-color);

  span {
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 0.025rem;
    line-height: 40px;
  }

  img {
    padding-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.list--item--text--description {
  font-size: 1rem;
  margin-top: 0.5rem;

  p {
    font-style: italic;
    margin-bottom: 0.5rem;
    word-break: break-word;
  }
}

.list--item--text--domain {
  color: var(--light-text-color);
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-size: 1rem;
  a {
    color: var(--light-text-color);
  }
}

.list--item--max--claims {
  color: var(--light-text-color);
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-size: 1rem;
}

.list--item--buttons {
  flex-grow: 1;
  text-align: right;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    text-decoration: none;
    display: inline-block;
    height: 40px;
    margin-left: 0.5rem;
  }
}

.list--items--buttons--left {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.list--items--buttons--sort {
  display: flex;
  flex-direction: row;
}

.list--items--buttons--left--button {
  background-color: white;
  border: none;
  padding: 0;
  margin-left: 0.5rem;
  cursor: pointer;
}

.list--items--buttons--right {
  display: inline-block;
}

.lists--item {
  border: 1px solid var(--hint-color);
  padding: 1em;
  display: grid;
  margin-bottom: 2em;
  position: relative;
}

.lists--bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.lists--actions {
  padding-bottom: 4px;
}

.lists--item--completed {
  img {
    opacity: 0.5;
  }
}

.lists--items {
  margin-left: 0;
  list-style: none;
}

.lists--item--entries {
  display: flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
  }
  img {
    max-width: 6rem;
    max-height: 6rem;
  }
}

.lists--item--author {
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: var(--light-text-color);
}

.lists--item--text {
  a {
    font-weight: 900;
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2em;
    letter-spacing: 0.025rem;
    word-break: break-word;
  }
  margin-bottom: 1rem;
}

.lists--item--buttons {
  text-align: right;

  a {
    text-decoration: none;
    margin-left: 0.5rem;
  }
}

.list--add--header a {
  font-weight: 900;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2em;
  letter-spacing: 0.025rem;
}

.list--add--cancel {
  color: var(--text-color);
}

.list--footer--buttons {
  display: block;
  width: 100%;
  text-align: center;

  .btn {
    border: 1px solid var(--action-fg-color);
    margin: 0.5rem 0;
  }
}

.flow-form {
  .list--description {
    width: 100%;
  }
  .list--description-html {
    display: none;
  }
}

.lists--modifiers {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
  > div {
    font-size: 0.8rem;
    margin-left: 1rem;
  }
}

.lists--modifiers--label {
  margin-right: 0.2rem;
}

.lists--modifiers--no-match {
  min-height: 12rem;
  text-align: center;
  padding-top: 3rem;

  span {
    border: 1px solid var(--hint-color);
    padding: 1rem;
  }
}

.list--count {
  margin-top: -1rem;
  color: var(--light-text-color);
  text-align: right;
}

@media screen and (max-width: 50rem) {
  .lists--item--entries {
    min-width: 12rem;
    a {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  .list--actions {
    grid-area: list-header-actions;
    margin: 0.5rem 0;
  }

  .list--header--buttons {
    margin: 0.5rem 0 1rem auto;
  }

  .list--header--back {
    margin-top: -1em;
  }

  .list--header {
    margin: 0;
    margin-bottom: 0.5rem;
    grid-template-areas:
      'list-header-title '
      'list-header-author'
      'list-header-actions'
      'list-header-buttons';
  }

  .list--author {
    margin-bottom: 0.5rem;
  }

  .list--header--title {
    margin: 0;
  }

  .list--item--inner {
    padding: 1rem;
    grid-template-areas:
      'listItemImage'
      'listItemText'
      'listItemDescription'
      'listItemCompleted'
      'listItemBottomRow';
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .list--item--completed--button {
    grid-area: listItemCompleted;
    display: flex;
    padding: 0rem;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .list--item--bottom-row {
    flex-direction: column;
  }

  .list--item--text {
    padding-right: 0;
    min-height: auto;
  }

  .list--item--text--header {
    flex-direction: column;
  }

  .list--item--text--claimed-wrapper {
    margin-top: 0;
    padding-left: 0;
  }

  .list--item--text--claimed {
    justify-content: center;
  }

  .list--item--text--link {
    max-width: none;
  }

  .list--item--image {
    padding-right: 0;
    a img,
    img {
      width: 10rem;
      height: 10rem;
      margin: 0;
    }
  }

  .list--item--buttons {
    text-align: right;
    padding-right: 0;
    padding-bottom: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
  }

  .list--items--buttons--left {
    flex-grow: 1;
    justify-content: left;

    a {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  .list--items--buttons--left--button {
    background-color: white;
    border: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .list--items--buttons--right {
    display: inline-block;
  }

  .list--item--import {
    text-align: center;
  }

  .lists--item--text {
    margin-bottom: 2rem;
  }
}
