.image-zoom-click {
  cursor: pointer;
}

.image-zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.image-zoom-close {
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: right;
  padding: 0;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 24px;
  cursor: pointer;
  color: white;
  text-shadow: 0px 0px 2px black, 0 0 25px white, 0 0 5px white;
}

.image-zoom-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image-zoom-target {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  a {
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
  z-index: 101;
}
