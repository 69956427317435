.btn {
  display: inline-block;
  background-color: var(--action-color);
  padding: 1em 2em;
  color: var(--action-fg-color);
  text-decoration: none;
  border-radius: 2em;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: center;
  min-width: 16em;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  font-weight: 900;
  letter-spacing: 0.025rem;
  margin: 2px;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0);

  @include focus;

  &:disabled {
    background-color: var(--deactive);
  }
}

.btn.btn--warning {
  background-color: var(--error-color);
}

.btn.btn--disabled {
  pointer-events: none;
  background-color: var(--deactive);
}

.btn--small {
  @extend .btn;
  min-width: 0;
  padding: 0.5em 1em;
}

.btn--small.btn--disabled {
  background-color: var(--deactive);
}

.btn--super--small {
  @extend .btn;
  min-width: 0;
  padding: 0.3em 0.7em;
  font-size: 0.8rem;
}

.btn--super--small.btn--disabled {
  background-color: var(--deactive);
}

.btn--border {
  @extend .btn;
  background-color: var(--main-bg-color);
  color: var(--action-color);
  border: 1px solid var(--action-color);
  min-width: auto;
  cursor: pointer;

  &:disabled {
    background-color: var(--main-bg-color);
    color: var(--deactive);
    border: 1px solid var(--deactive);
  }
}

.btn--border.btn--disabled {
  background-color: var(--main-bg-color);
  color: var(--deactive);
  border: 1px solid var(--deactive);
}

.btn--border--deactive {
  @extend.btn--border;
  color: var(--deactive);
  border: 1px solid var(--deactive);
  cursor: default;
}

.btn--link {
  border: none;
  outline: none;
  background-color: var(--main-bg-color);
  color: var(--link-color);
  font-weight: 400;
  padding: 0;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    outline: none;
  }
}
