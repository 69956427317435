.welcome--content {
  display: grid;
  grid-template-areas: 'header header' 'list screenshots' 'explanation screenshots' 'new-link new-link' 'testimonial testimonial';
}

.welcome--headline {
  grid-area: header;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 0.025em;
}

.welcome--lead {
  margin-bottom: 2rem;
}

.welcome--explanation {
  grid-area: explanation;
}

.welcome--screenshots {
  grid-area: screenshots;
  img {
    width: 20rem;
  }
  margin-left: 2rem;
  margin-top: 3rem;
}

.welcome--list {
  grid-area: list;
  padding: 1rem 0rem 1rem 0rem;
  margin-left: 0;
  font-size: 1.2rem;
  list-style: none;
  li {
    margin-left: 0rem;
  }

  li::before {
    content: '+'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--success); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 0; /* Also needed for space (tweak if needed) */
  }
}

.welcome--testimonial {
  grid-area: testimonial;
  margin-bottom: 2rem;
  border: 1px solid var(--hint-color);
  padding: 2rem;
  text-align: center;
}
.welcome--testimonial * {
  font-style: italic;
}

.welcome--testimonial--quote {
  font-size: 2rem;
}

.welcome--testimonial--person {
  padding-top: 1rem;
  font-size: 1.5rem;

  a {
    color: var(--action-color);
  }
}

.welcome--new-link {
  grid-area: new-link;
  padding: 1rem 0 2rem 0;
}

.welcome--logged-in {
  margin-top: 2rem;

  div:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.welcome--log-out {
  margin: 4rem 0;
}

.welcome--elefant {
  cursor: pointer;
  position: absolute;
  bottom: -3.5rem;
  right: 0;
}

.welcome--instagram--list {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.welcome--instagram--post {
  img {
    border: 1px solid var(--hint-color);
    height: 245px;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    width: 245px;
  }
}

.welcome--blogposts--title {
  margin-top: 2rem;
}

.welcome--blogposts {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
}

.welcome--blogpost {
  border: 1px solid var(--hint-color);
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  width: 245px;
  a {
    font-size: 1rem;
  }
}

.welcome--blogpost--title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  color: var(--link-color);
  font-size: 1rem;
  text-decoration: underline;
}

.welcome--blogpost--image {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.welcome--blogposts--more {
  margin-bottom: 6rem;
}

@media screen and (max-width: 50rem) {
  .welcome {
    margin-top: 0;
  }

  .welcome--content {
    display: block;
    grid-template-areas: none;
  }

  .welcome--headline {
    margin-top: 0;
    font-size: 1.7rem;
    text-align: center;
    width: 100%;
  }

  .welcome--list {
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;

    li {
      max-width: max-content;
    }
  }

  .welcome--explanation {
    text-align: center;
  }

  .welcome--lead {
    margin-bottom: 3rem;
  }

  .welcome--bottom-links {
    margin-top: 0rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .welcome--new-link {
    text-align: center;
    a {
      margin-bottom: 1rem;
    }
  }
  .welcome--screenshots {
    margin-top: 1rem;
    margin-bottom: 10rem;
    text-align: center;
    img {
      max-width: 100vw;
    }
  }

  .welcome--elefant {
    margin-right: 1rem;
  }

  .welcome--instagram--title {
    text-align: center;
  }

  .welcome--instagram--list {
    justify-content: center;
  }

  .welcome--instagram--more {
    text-align: center;
  }

  .welcome--instagram {
    justify-content: center;
  }

  .welcome--blogposts--title {
    text-align: center;
  }

  .welcome--blogposts--more {
    text-align: center;
  }

  .welcome--blogposts {
    justify-content: center;
  }

  .welcome--testimonial--quote {
    font-size: 1.7rem;
  }

  .welcome--testimonial--person {
    font-size: 1.1rem;
  }
}
