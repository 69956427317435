.share--action {
  margin-top: 1rem;
}

.share--copy {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background-color: var(--action-color);
  color: var(--header-font-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  display: none;
  opacity: 0;
}

@media screen and (max-width: 50rem) {
  .share--copy {
    min-width: 10rem;
    width: 100%;
  }
}
