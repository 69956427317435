.shared-list--header {
  width: 100%;

  h1 {
    margin-bottom: 0.2rem;
  }
}

.shared-list--description-html {
  margin-bottom: 1rem;
}

.shared-list--sub-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.shared-list--author {
  margin-bottom: 1rem;
  color: var(--light-text-color);
}

.shared-list--sort {
  text-align: right;
}
.shared-list--sort--label {
  margin-right: 0.5rem;
}

.shared-list--header--title {
  width: 100%;
}

.shared-list--actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    margin-right: 1rem;
  }
}

.shared-lists--item--completed {
  img {
    opacity: 0.5;
  }
}

.shared-list--items {
  list-style: none;
  margin-left: 0;
}

.shared-list--item {
  border: 1px solid var(--hint-color);
  display: grid;
  margin-bottom: 2em;
  grid-template-areas: 'sharedListItemImage sharedListItemText sharedListItemButtons';
  grid-template-columns: auto 1fr auto;
}

.shared-list--item--claimed {
  border: 1px solid var(--deactive);

  .shared-list--item--image {
    opacity: 0.5;
  }
  .shared-list--item--text {
    opacity: 0.5;
  }
  .shared-list--item--claim--count {
    opacity: 0.5;
  }
}

.shared-list--item--mine {
  border: 1px solid var(--success);
}

.shared-list--item--image {
  grid-area: sharedListItemImage;
  padding-right: 1rem;
  line-height: 0;

  a img,
  img {
    width: 12rem;
    height: 12rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
  }
}

.shared-list--item--text {
  grid-area: sharedListItemText;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  font-weight: 900;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shared-list--item--isbn {
  font-size: 1rem;
  letter-spacing: 0.025rem;
  font-weight: 900;
  color: var(--text-color);
  padding-bottom: 0.5rem;
}

.shared-list--item--price {
  font-size: 1rem;
  letter-spacing: 0.025rem;
  font-weight: 900;
  color: var(--text-color);
}

.shared-list--item--text--link {
  margin-bottom: 0.5rem;
}

.shared-list--item--text--link,
.shared-list--item--text--link * {
  font-weight: 900;
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: max-content;
}

.shared-list--item--text--description {
  font-size: 1rem;

  p {
    font-style: italic;
    margin-bottom: 0.5rem;
    word-break: break-word;
  }
}

.shared-list--item--text--domain {
  color: var(--light-text-color);
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-size: 1rem;
  max-width: max-content;

  a {
    color: var(--light-text-color);
  }
}

.shared-list--item--buttons {
  grid-area: sharedListItemButtons;
  text-align: right;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.shared-list--item--claim-button {
  position: relative;
  margin: auto 0;
  padding-top: 35px;
  min-width: 10rem;
}

.shared-list--item--claimed-by {
  position: relative;
  margin: auto 0;
  padding-top: 35px;
  max-width: 16rem;
  color: var(--text-color);
}

.shared-list--item--buttons--corner {
  text-align: right;
  width: 100%;

  a {
    margin-left: 0.5rem;
  }
}

.shared-list--item--buttons--corner--copy {
  display: inline-block;
  height: 35px;
  img {
    width: 35px;
  }
}

.shared-list--item--buttons--corner--copy--text {
  @extend .btn--small;
  margin-right: -0.5rem;
  img {
    margin-bottom: -1px;
    width: 1rem;
    margin-right: 0.2rem;
  }
}

.shared-list--item--success {
  grid-area: sharedListItemButtons;
  text-align: right;
  display: flex;
  flex-direction: column;
}

.shared-list--item--complete-warning {
  color: var(--link-color);
  font-size: 1rem;
}

.shared-list--item--success--text {
  flex-grow: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--light-text-color);

  span {
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 0.025rem;
    line-height: 40px;
  }

  img {
    padding-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.shared-list--item--claim--count {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--light-text-color);

  a {
    text-decoration: underline;
    color: var(--light-text-color);
  }
}

.shared-list--item--success--button {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-right: 1rem;
  flex-direction: column;
}

.shared-list--item--success--link {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-right: 0rem;
  a {
    display: flex;
    align-items: center;
    flex-grow: 0;
    color: var(--text-color);
    margin-right: 1rem;
    img {
      width: 35px;
    }
  }
}

.shared-list--hint {
  background-color: var(--footer-bg-color);
  color: black;
  margin-bottom: 1rem;
  padding: 1rem 2rem 0.5rem 2rem;
}

.shared-lists--items {
  margin-left: 0;
  list-style: none;
}

.shared-lists--item {
  border: 1px solid var(--hint-color);
  padding: 1em;
  display: grid;
  margin-bottom: 2em;
  position: relative;
}

.shared-lists--item--entries {
  display: flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
  }
  img {
    max-width: 6rem;
    max-height: 6rem;
  }
}

.shared-lists--item--author {
  margin-bottom: 1rem;
  color: var(--light-text-color);
}

.shared-lists--item--text {
  a {
    font-weight: 900;
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2em;
    letter-spacing: 0.025rem;
    word-break: break-word;
  }
}

.shared-lists--item--buttons {
  text-align: right;

  a {
    text-decoration: none;
    margin: 0 0 0 0.5rem;
  }
}

.shared-lists--modifiers {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
  > div {
    font-size: 0.8rem;
    margin-left: 1rem;
  }
}

.shared-lists--modifiers--label {
  margin-right: 0.2rem;
}

.shared-list--show-claim {
  border: 5px solid white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0rem;
  background-color: white;
  left: 0;
  right: 0;
  padding-top: 35px;
  button {
    width: 10rem;
  }
}

.shared-list--count {
  margin-top: -1rem;
  color: var(--light-text-color);
  text-align: right;
}

@media screen and (max-width: 50rem) {
  .shared-list--actions {
    padding-top: 1rem;
  }

  .shared-list--item {
    position: relative;
    padding: 1rem;
    grid-template-areas:
      'sharedListItemImage'
      'sharedListItemText'
      'sharedListItemButtons';
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .shared-list--item--text {
    padding-right: 0;
    min-height: auto;
  }

  .shared-list--item--text--domain,
  .shared-list--item--text--link {
    max-width: none;
  }

  .shared-list--item--image {
    padding-right: 0;
    a img,
    img {
      width: 10rem;
      height: 10rem;
      margin: 0;
    }
  }

  .shared-list--item--success--text {
    text-align: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    img {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }

  .shared-list--item--success--button {
    justify-content: center;
    margin-bottom: 1rem;
    padding-right: 0;
  }

  .shared-list--item--success--link {
    margin-bottom: 0;
    margin-right: -1rem;
  }

  .shared-list--item--buttons {
    padding-right: 0;
    padding-bottom: 0;
    margin-top: 1rem;
    justify-content: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .shared-list--item--claim-button {
    width: 15rem;
    text-align: center;
  }

  .shared-list--show-claim {
    button {
      width: 15rem;
    }
  }
}

@media screen and (max-width: 40rem) {
  .shared-list--sub-header {
    flex-direction: column;
  }

  .shared-list--sort {
    margin-top: 1rem;
  }

  .shared-lists--item--entries {
    min-width: 12rem;
    a {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  .shared-lists--item--text {
    margin-bottom: 2rem;
  }
}
