.how_it_works {
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 1rem auto;
  }

  p {
    margin: 1rem 0;
  }

  h2 {
    margin-top: 2rem;
  }
}
