@import '../node_modules/cookieconsent/build/cookieconsent.min.css';

@import '_colors.scss';
@import '_focus.scss';
@import '_fonts.scss';
@import '_reset.scss';
@import 'components/_alert.scss';
@import 'components/_button.scss';
@import 'components/_christmas.scss';
@import 'components/_delete_dialog.scss';
@import 'components/_dialog.scss';
@import 'components/_footer.scss';
@import 'components/_forms.scss';
@import 'components/_header.scss';
@import 'components/_html_editor.scss';
@import 'components/_main.scss';
@import 'components/_popup.scss';
@import 'components/_spinner.scss';
@import 'controllers/_add_wish.scss';
@import 'controllers/_claim.scss';
@import 'controllers/_collapsable.scss';
@import 'controllers/_copy.scss';
@import 'controllers/_hint_dialog.scss';
@import 'controllers/_enhanced_select.scss';
@import 'controllers/_mark_completed.scss';
@import 'controllers/_image_zoom.scss';
@import 'controllers/_share_dialog.scss';
@import 'controllers/_share.scss';
@import 'pages/_about.scss';
@import 'pages/_add_entry.scss';
@import 'pages/_blog.scss';
@import 'pages/_claim_show.scss';
@import 'pages/_contest.scss';
@import 'pages/_copy.scss';
@import 'pages/_error.scss';
@import 'pages/_gift_ideas.scss';
@import 'pages/_how_it_works.scss';
@import 'pages/_list_claims.scss';
@import 'pages/_list_entries.scss';
@import 'pages/_lists.scss';
@import 'pages/_login.scss';
@import 'pages/_prismic.scss';
@import 'pages/_qrcode.scss';
@import 'pages/_share_explanation.scss';
@import 'pages/_shared_lists.scss';
@import 'pages/_sponsor.scss';
@import 'pages/_user.scss';
@import 'pages/_welcome.scss';

/*
 * Globals
 */
*,
body {
  font-family: 'Lato', sans-serif;
}

html {
  height: 100%;
  overflow: auto;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
  background-color: var(--bg-color);
}

body {
  display: flex;
  color: var(--text-color);
}

h1 {
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 0.5em;
}

h2 {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

h2,
p {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}

a {
  color: var(--link-color);
}

p,
ul,
ol {
  margin-bottom: 1rem;
  line-height: 1.5em;
}

ul {
  margin-left: 1rem;
}

strong {
  font-weight: 700;
  font-style: unset;
}

em,
i {
  font-weight: unset;
  font-style: italic;
}

.container {
  width: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.js-click-pointer {
  cursor: pointer;
}

