.gift-idea--title {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.gift-idea--lists {
  margin-left: 0;
  list-style: none;
}

.gift-idea--list {
  border: 1px solid var(--hint-color);
  padding: 1em;
  display: grid;
  margin-bottom: 2em;
  position: relative;

  &--text {
    a {
      font-weight: 900;
      color: var(--text-color);
      text-decoration: none;
      font-size: 1.2em;
      letter-spacing: 0.025rem;
      word-break: break-word;
    }

    margin-bottom: 1rem;
  }
}

.gift-idea--list--items {
  margin-left: 0;
  list-style: none;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  height: 6rem;
}

.gift-idea--list--bottom {
  display: flex;
  justify-content: space-between;
}

.gift-idea--list--hints {
  margin-top: auto;
}

.gift-idea--list--buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.gift-idea--list--item {
  img {
    max-width: 6rem;
    max-height: 6rem;
  }
}

@media screen and (max-width: 50rem) {
  .gift-idea--list--items {
    height: 3rem;
  }

  .gift-idea--list--item {
    img {
      max-width: 3rem;
      max-height: 3rem;
    }
  }

}