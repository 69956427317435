.share--dialog--subtitle {
  margin-top: 1rem;
  display: block;

  border: none;
  outline: none;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  font-weight: 700;
  padding: 0;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.share--dialog--explanation {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.share--dialog--url {
  width: 100%;
  min-width: auto !important;
}

.share--dialog--input {
  position: relative;
}

.share--dialog--chevron {
  height: 1rem;
  margin-right: 0.5rem;
}

.share--dialog--copy {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--action-color);
  color: var(--header-font-color);
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: none;
  opacity: 0;
}

.share--dialog--action {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share--dialog--sharing {
  overflow: hidden;
}
.share--dialog--editing {
  height: 0;
  overflow: hidden;
}

.share--dialog--button {
  display: flex;
  align-items: center;
}

.share--dialog--button--with-icon {
  border: none;
  outline: none;
  background-color: var(--main-bg-color);
  cursor: pointer;
}

.share--dialog--arrow {
  height: 2rem;
  margin-right: 0.5rem;
}
