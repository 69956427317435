input.validation:not([type='checkbox']) {
  &:invalid {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
}

input.form-puck {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

input.validation {
  &:invalid {
    border-bottom: solid 1px var(--error-color);
    box-shadow: none;
  }
}

textarea.validation {
  &:invalid {
    border: solid 1px var(--error-color) !important;
    box-shadow: none;
  }
}

.form-row--checkbox {
  line-height: 24px;
}

input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}

input[type='checkbox']:focus-visible+label::before {
  outline: rgb(59, 153, 252) auto 5px;
  outline-offset: 2px;
}

input[type='checkbox']+label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type='checkbox']:disabled+label {
  color: var(--deactive);
  cursor: default;
}

input[type='checkbox']+label:last-child {
  margin-bottom: 0;
}

input[type='checkbox']+label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--success);
  position: absolute;
  left: 0;
  top: 2px;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type='checkbox']:disabled+label:before {
  border: 1px solid var(--deactive);
}

input[type='checkbox']:checked+label:before {
  border: 2px solid var(--success);
  width: 10px;
  left: 5px;
  top: 0;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type='checkbox']:checked:disabled+label:before {
  border: 2px solid var(--deactive);
  border-top-color: transparent;
  border-left-color: transparent;
}

.form-group {
  input.validation:invalid~label {
    color: var(--error-color);
  }
}

.form-enhance {
  display: none;
}

.flow-form {
  input:not([type='checkbox']) {
    font-size: 1rem;
    padding: 1rem 0px;
    height: 3em;
    border: none;
    border-radius: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    min-width: 24em;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;

    &:focus {
      border-bottom: solid 1px black;
      outline: 0;
      box-shadow: 0 2px 6px -8px rgba(black, 0.45);
    }
  }

  textarea {
    font-family: 'Lato', sans-serif;

    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    min-width: 20em;
    min-height: 1em;
    box-sizing: border-box;
    transition: all 0.3s linear;
    color: #000;
    font-weight: 400;
    resize: vertical;
    -webkit-appearance: none;

    &:focus {
      border: solid 1px black;
      outline: 0;
      box-shadow: 0 2px 6px -8px rgba(black, 0.45);
    }
  }

  .form-row {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .form-group {
    position: relative;
    margin-bottom: 2em;
    margin-top: 2em;

    label.no-collapse {
      position: relative;
      opacity: 1;
      top: 0;
      margin-bottom: 0.5rem;
      padding-right: 0.5rem;
    }

    label {
      color: var(--link-color);
      position: absolute;
      top: calc(1rem);
      left: 0;
      opacity: 0;
      transition: all 0.3s ease;
      pointer-events: none;
    }

    input:not(:placeholder-shown) {
      padding: 2em 0 1em 0;
    }

    input:not(:placeholder-shown)+label {
      transform: translateY(-1rem);
      opacity: 1;
      pointer-events: all;
    }

    textarea:not(:placeholder-shown) {
      margin: 2em 0 1em 0;
    }

    textarea.textarea--without-placeholder+label,
    textarea:not(:placeholder-shown)+label {
      transform: translateY(-1rem);
      opacity: 1;
      pointer-events: all;
    }
  }

  .form-buttons {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 26rem;
    display: flex;

    a {
      margin: 1rem 2rem 0 2rem;
      color: var(--text-color);
    }
  }

  .form-buttons--left-aligned {
    a {
      margin-left: 0px;
    }
  }

  .help-block {
    margin-top: 0.5em;
    color: var(--error-color);
  }

  .hint-block {
    margin-top: 1em;
    color: var(--light-text-color);
    font-size: 0.8rem;
    width: 24rem;
  }

  .warn-block {
    margin-top: 1em;
    color: var(--link-color);
    font-size: 0.8rem;
  }
}

.form-select {
  color: red;
  display: inline-block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ff9933%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22/%3E%3C/svg%3E');

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  option {
    font-weight: normal;
  }
}

.form-select--small {
  @extend .form-select;
  padding: 0.2em 1.2em 0.2em 0.4em;
  font-weight: normal;
  font-size: 0.8rem;
  background-position: right 0.4em top 50%, 0 0;
}

.form-select--multiline {
  color: red;
  display: inline-block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 0.8em 0.5em 0.8em;
  box-sizing: border-box;
  margin: 0 0 1rem 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  option {
    padding: 0.4rem;
    font-weight: normal;
  }

  &:focus option:checked {
    background: var(--success) linear-gradient(0deg, var(--success) 0%, var(--success) 100%);
  }
}

@media screen and (max-width: 50rem) {
  .flow-form {
    .form-buttons {
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
    }

    input:not([type='checkbox']) {
      min-width: 10rem;
      width: 100%;
    }

    .hint-block {
      width: 100%;
    }

    textarea {
      min-width: 10rem;
    }
  }

  .form-select--small {
    padding: 0.2em 1.5em 0.2em 0.4em;
  }
}

fieldset {
  border: 1px solid var(--hint-color);
  padding: 0 2rem;
  margin-top: 2rem;

  p {
    margin-top: 2rem;
  }
}

legend {
  padding: 0 1rem;
}