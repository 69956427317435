.copy--popup {
  a {
    padding: 0 !important;
    margin: 0 !important;
  }

  .copy--popup--spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .copy--popup--headline {
    font-weight: 700;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--deactive);
  }

  .copy--popup--list {
    max-height: 200px;
    overflow-y: scroll;
  }

  .copy--popup--list--entry {
    padding: 0.5rem 1rem !important;
    cursor: pointer;
    display: block;
    color: var(--text-color);
    margin: 0;
    line-height: 1rem;
    text-decoration: none;

    &:focus,
    &:hover {
      background-color: var(--action-color);
      color: var(--action-fg-color);
    }
  }

  .copy--popup--create-list--button {
    border: none;
    outline: none;
    background-color: var(--main-bg-color);
    color: var(--link-color);
    width: 100%;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem;
    border-top: 1px solid var(--deactive);

    display: flex;
    justify-content: center;
    align-content: center;
    img {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
    span {
      margin-top: 0.1rem;
    }

    &:focus,
    &:hover {
      outline: none;
      background-color: var(--action-color);
      color: var(--action-fg-color);

      .copy--popup--create-list--plus {
        background: url(/images/plus_green.svg);
        background-size: contain;
      }
    }
  }

  .copy--popup--create-list::-moz-focus-inner {
    border: 0;
  }
}

.copy--popup--create-list--plus {
  background: url(/images/plus.svg);
  background-size: contain;
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.copy--popup--empty {
  padding: 16px;
  margin: 0;
  text-align: center;
}

.copy--popup--login {
  padding: 16px 16px 0 16px;
  margin: 0;
  text-align: center;
  a {
    height: auto !important;
  }
}

.copy--popup--waiting {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy--popup--success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: var(--action-color);
  color: white;
  font-size: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy--dialog--spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 50rem) {
  .copy--popup {
    .copy--popup--wrapper {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
    }

    .copy--popup--headline {
      padding: 1rem;
    }

    .copy--popup--list {
      flex-grow: 1;
      max-height: none;
      padding-top: 1rem;
    }

    .copy--popup--login {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .copy--popup--create-list {
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      border-top: 1px solid var(--deactive);
      padding-top: 1rem;
    }

    .copy--popup--create-list--button {
      margin-left: auto;
      margin-right: auto;
      width: auto;
      display: flex;
      background-color: var(--action-color);
      color: var(--action-fg-color);
      text-decoration: none;
      border-radius: 2em;
      text-align: center;
      font-size: 1rem;
      border: none;
      cursor: pointer;
      font-weight: 900;
      letter-spacing: 0.025rem;
      box-sizing: border-box;
      border: 2px solid rgba(0, 0, 0, 0);

      padding: 0.3em 0.7em;

      span {
        margin-top: 0;
      }
    }
  }
}
