@mixin focus {
  &:focus {
    box-shadow: 0 0px 5px 0px black;
    outline: none !important;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
