.claim--show--list {
  margin: 0 0 1rem 0;
}

.claim--show--list-header {
  margin: 0;
  a {
    font-size: 1.2rem;
    letter-spacing: 0.025rem;
    color: var(--text-color);
    font-weight: 900;

    text-decoration: underline;
  }
}
