footer {
  grid-area: footer;
  background-color: var(--footer-bg-color);
  background-image: url(/images/footer-background.png);

  .inner {
    width: 50rem;
    padding-top: 2em;
    margin: 0 auto;
    color: var(--footer-font-color);
    text-align: center;
    font-size: 0.8em;

    display: flex;
    flex-direction: row;
    justify-content: center;

    p {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    a {
      text-decoration: none;
      color: var(--footer-font-color);
    }
  }

  .affiliate-hint {
    padding: 1rem 1rem 2rem 1rem;
    margin: 0 auto;
    color: var(--footer-font-color);
    text-align: center;
    font-size: 0.8em;

    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.footer--instagram {
  width: 1rem;
}

.footer--twitter {
  width: 1rem;
}

.footer--social {
  display: flex;
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 50rem) {
  footer {
    .inner {
      width: 100%;
      padding: 0.5rem 0 0.5rem 0;
      flex-direction: column;
      font-size: 1rem;

      p {
        padding: 0.5rem 0rem;
      }
    }

    .footer--social {
      margin: 0 auto;

      p+p {
        margin-top: 0;
        margin-left: 12px;
      }
    }
  }
}