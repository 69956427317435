.login-buttons {
  .btn {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  a {
    display: inline-block;
    padding-bottom: 0.5rem;
  }
}
