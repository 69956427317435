.collapsable {
  border: none;
  outline: none;
  background-color: var(--main-bg-color);
  color: var(--link-color);
  font-weight: 400;
  padding: 0;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  position: absolute;
  top: 1rem;
  right: 1rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    outline: none;
  }
}
