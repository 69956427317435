.add-entry--form {
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid var(--hint-color);
  display: flex;
  flex-direction: row;
  align-content: center;
}

.add-entry--row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.add-entry--plus {
  font-size: 3rem;
  line-height: 3rem;
  color: var(--action-color);
  padding-right: 1rem;
  text-decoration: none;
}

.add-entry--input {
  flex-grow: 1;
  margin-right: 2rem;
}

.add-entry--list-select {
  margin-bottom: 1rem;
}

@media screen and (max-width: 50rem) {
  .add-entry--form {
    flex-direction: column;
  }

  .add-entry--form {
    button {
      margin-top: 2rem;
    }
  }
}