.list--entry--title {
  margin-bottom: 2rem;

  a {
    text-decoration: none;
    font-weight: 900;
    letter-spacing: 0.025rem;
    color: var(--text-color);
  }
}

.list--entry {
  border: 1px solid var(--hint-color);
  display: grid;
  grid-template-areas: 'listEntryImage listEntryInput listEntryButtons';
  grid-template-columns: auto 1fr;
  margin-bottom: 2rem;

  .form-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;

    textarea,
    input {
      width: 100%;
    }
  }
}

.list--entry--add {
  display: none;
  font-size: 3rem;
  color: var(--action-color);
  padding-left: 1rem;
  padding-top: 0.5rem;
  text-decoration: none;
}

.list--entry--image {
  display: inline-block;
  grid-area: listEntryImage;
  position: relative;
  line-height: 0;

  * input {
    display: none;
  }
}

.list--entry--image--edit {
  position: absolute;
  left: calc(6.5rem - 30px);
  top: calc(6.5rem - 30px);
}

.list--entry--input {
  grid-area: listEntryInput;
  padding-left: 1rem;
  padding-right: 2rem;

  input {
    min-width: 15rem !important;
  }
}

.list--entry--buttons {
  grid-area: listEntryButtons;
  text-align: center;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;

  a {
    padding-top: 1rem;
    display: inline-block;
    color: var(--text-color);
  }
}

.dragging * .list--entry--image--preview {
  outline: 4px dashed var(--success);
}

.list--entry--image--preview {
  object-fit: contain;
  width: 12rem;
  height: 12rem;
  margin: 0.5rem 0 0.5rem 0.5rem;
}

.list--entry--price--row {
  display: flex;
  flex-direction: row;
}

.list--entry--price--row--explanation {
  display: none;
  margin-top: 16px;
  margin-bottom: 4px;
  color: var(--light-text-color);
  font-style: italic;
}

.list--entry--price--currency {
  margin: auto 0 0.7rem 0;

  .form-select {
    border: none;
    margin-left: 0.5rem;
    padding: 0;
    outline: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
    text-align: right;
  }
}

.list--entry--input input.list--entry--price--value {
  min-width: 8rem;
}

.list--entry--buttons--cancel {
  margin-top: 0.5rem;
  display: inline-block;
}

.list--entry--description textarea {
  font-style: italic;
}

.flow-form .form-group.list--entry--max-claims {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.flow-form .form-group.list--entry--max-claims label {
  position: relative;
  display: block;
  top: auto;
  left: auto;
  opacity: 1;
  color: var(--link-color);
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.list--entry--import--mode {
  position: relative;
  .list--entry--isbn,
  .list--entry--buttons--cancel,
  .list--entry--image,
  .list--entry--link,
  .list--entry--max-claims,
  .list--entry--description,
  .list--entry--price--row,
  .list--entry--price--row--explanation {
    display: none;
    max-height: 0;
    /*and eventually delay an overflow:auto; */
    overflow: hidden;
    transition: max-height 0.5s, overflow 0s;
  }
  .list--entry--add {
    display: block;
  }
}

.list--entry--edit--mode {
  position: relative;

  .list--entry--price--row--explanation {
    display: none;
  }

  .list--entry--image,
  .list--entry--link,
  .list--entry--max-claims,
  .list--entry--price--row {
    max-height: 25em;
    overflow: auto;
    transition: max-height 0.5s, overflow 0.5s 0.5s;
  }
}

@media screen and (max-width: 50rem) {
  .list--entry {
    grid-template-areas: 'listEntryImage' 'listEntryInput' 'listEntryButtons';
    grid-template-columns: 1fr;
  }

  .list--entry--image {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    height: auto;
  }

  .list--entry--image--edit {
    left: calc(50% - 33px);
    top: calc(50% - 33px);
  }

  .list--entry--import--mode {
    .list--entry--add {
      display: none;
    }
  }

  .list--entry--buttons {
    padding-top: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
  }

  .list--entry--image--preview {
    margin: 0 0 1rem 0;
  }

  .list--entry--input {
    padding-left: 1rem;
    padding-right: 1rem;

    input {
      min-width: 10rem;
    }
  }

  .list--entry--input input.list--entry--price--value {
    min-width: 4rem;
  }
}
