.js-hint-dialog--close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -0.5rem;
  margin-right: -1.5rem;

  button {
    background-color: rgba(0,0,0,0);
    border: none;
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    cursor: pointer;
  }
}