main {
  margin: 3rem auto 2rem auto;
  width: 50rem;
  grid-area: main;
  position: relative;
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 50rem) {
  main {
    margin: 6rem auto 2rem auto;
    width: 100%;
    padding: 0 1rem;
  }
}
