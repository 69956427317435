.popup {
  border: 1px solid var(--deactive);
  position: absolute;
  background-color: white;
  width: 250px;
  z-index: 10;
  text-align: left;
  box-shadow: 0px 0px 5px #ccc;
  margin: 0 !important;
}

.popup-close {
  display: none !important;
}

@media screen and (max-width: 50rem) {
  .popup {
    border: 1px solid var(--deactive);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 10px #aaa;
    min-height: 70%;
    max-height: 90%;
    flex-grow: 1;
    overflow: hidden;
    margin: 0 !important;
  }

  .popup-close {
    display: block !important;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 1.25em;
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    cursor: pointer;
    transition: 0.15s;
  }
}
