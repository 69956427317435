.contest-page {
  h1 {
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
  li {
    margin-bottom: 1rem;
  }

  ol {
    margin-left: 1.7rem;
  }
}
