.qrcode--back {
  margin-bottom: 0.5rem;
}

.qrcode--image {
  width: 15rem;
  margin-left: -15px;
}

.qrcode--main {
  display: flex;
  flex-direction: row;
}

.qrcode--info {
  margin-top: 1rem;
  margin-left: 1rem;
}

@media screen and (max-width: 50rem) {
  .qrcode--link {
    text-align: center;
  }

  .qrcode--image {
    margin: auto;
  }

  .qrcode--main {
    flex-direction: column;
  }

  .qrcode--info {
    margin-top: 1rem;
    margin-left: 0;
  }
}
