.alert {
  padding: 1em;
  margin: 1em 0;
  animation: 0.1s linear 10s cssAnimation;
  animation-fill-mode: forwards;
  overflow: hidden;
}
@keyframes cssAnimation {
  from {
    opacity: 1;
  }
  to {
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
  }
}
.alert-info {
  border: 2px solid var(--action-color);
}

.alert-danger {
  border: 2px solid var(--hint-color);
}
