.dialog-list-claims--body {
  margin: 1rem 0;
  max-height: 200px;
  overflow: scroll;
}

.list-claims--table {
  margin: 0;
  padding: 0;
  max-width: 40rem;

  tr {
    border-bottom: 1px solid var(--deactive);
    td {
      padding: 1rem 0 1rem 1rem;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: var(--deactive-bg);
    }
  }
}

.list-claims--table--count {
  padding-right: 1rem;
  text-align: right;
}

.list-claims--table--name {
  width: 100%;
}

.list-claims--icon {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
}
