.blog--post {
  border: 1px solid var(--hint-color);
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
}

.blog--post--date {
  font-size: 0.8rem;
  margin-top: -0.5rem;
  color: var(--light-text-color);
}

.blog--post--image {
  width: 300px;
  height: 300px;
  margin-right: 1rem;
  object-fit: cover;
  display: block;
}

@media screen and (max-width: 50rem) {
  .blog--post--image {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
  }
}