.share-explanation {
  h1 {
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 2rem;
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }

  input {
    width: 100%;
  }

}

.share-explanation--button {
  position: relative;
}

p.share-explanation--to-list {
  margin-top: 1.5rem;
}

@media screen and (max-width: 50rem) {
  .share-explanation {
      text-align: center;

      h1 {
        font-size: 1.7rem;
      }
  }
}
