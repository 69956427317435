.about-page--photo {
  margin-top: 2rem;
  width: 500px;
  text-align: right;
  margin-bottom: 2rem;
  img {
    width: 500px;
  }
}

.about-page {
  h2 {
    margin: 2rem 0;
  }

  hr {
    border-bottom: none;
    border-top: 1px solid var(--deactive);
    margin: 1rem 3rem 2rem 3rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 50rem) {
  .about-page--photo {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
