.prismic-page {
  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  ol {
    margin-left: 1.7rem;
  }

  img {
    max-width: 100%;
    max-height: 540px;
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h2 a {
    font-weight: 900;
    color: black;
  }

  em {
    font-style: italic;

    a {
      font-style: italic;
    }
  }

  ul.shared-list--items {
    margin-top: 2rem;

    li {
      margin-left: 0;
      padding-left: 0;
    }
  }

  .prismic--image-sub {
    font-size: 0.8rem;
  }

  .prismic--big-button {
    a {
      @extend .btn;
    }
  }

  .prismic--copy--list {
    @extend .btn--small;
    margin-top: -1rem;
    margin-bottom: 2rem;
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem;

    span {
      font-weight: 900;
      letter-spacing: 0.025rem;
      font-size: 1rem;
    }

    img {
      width: 2rem;
    }
  }

  .prismic--image-two-row {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    margin-bottom: 1rem;

    img {
      flex: 0.5;
      max-height: unset;
      min-width: 10%;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }

  .prismic--image-three-row {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    margin-bottom: 1rem;

    img {
      flex: 0.33;
      max-height: unset;
      min-width: 10%;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}

.prismic--title {
  margin-bottom: 1rem;
}

.prismic--subtitle {
  margin-bottom: 0.2rem;
  font-weight: normal;
}

.prismic--date {
  font-size: 0.8rem;
  color: var(--light-text-color);
}

.affiliate-hint {
  font-size: 0.8rem;
}

@media screen and (max-width: 30rem) {
  .prismic-page {

    .prismic--image-two-row,
    .prismic--image-three-row {
      flex-direction: column;

      img {
        flex: auto;

        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: 1rem;
        }

        width: unset;
        max-width: 100%;
        max-height: unset;
      }
    }
  }
}