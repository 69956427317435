.html-editor {
  width: 100%;

  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  p {
    margin-top: 1rem;
  }

  .ProseMirror {
    border: solid 1px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    transition: border 0.3s linear;
    outline: none;
  }

  .ProseMirror-focused {
    border: solid 1px rgba(0, 0, 0, 1);
  }
}

.html-editor--buttons {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  padding: 4px;
  button {
    &:not(:last-child) {
      border-right: 1px solid var(--light-text-color);
    }
    background-color: var(--bg-color);
    border: none;
    font-size: 1rem;
    min-width: 2rem;
    height: 1.8rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
  .html-editor--buttons--bold {
    font-weight: 700;
    color: var(--text-color);
  }
  .html-editor--buttons--italic {
    font-style: italic;
    color: var(--text-color);
  }
}

.html-editor--footer {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
}

.html-editor--actions-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.html-editor--actions-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
