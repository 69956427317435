.claim--dialog {
  input {
    width: 100%;
  }
  .flow-form .form-group {
    margin-bottom: 0;
    .form-buttons {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
